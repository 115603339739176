<template>
  <!-- Loader -->
  <full-page-loader :text="text" class="full-page-loader"></full-page-loader>
</template>

<script>
import FullPageLoader from "../components/FullPageLoader.vue";
import mixins from "@/mixins/mixins";
import { mapActions } from "vuex";

export default {
  mixins: [mixins],
  components: { FullPageLoader },

  data() {
    return {
      text: "Odota...",
    };
  },

  async created() {
    try {
      if (this.accountName) this.text = `Ohjataan tilille ${this.accountName}...`;

      const path = this.$route.query.path || "/overview";
      const accountId = this.$route.query.acc || null;

      if (accountId) {
        await this.switchAccount(accountId);
        setTimeout(() => {
          window.location.replace(path);
        }, 700);
      } else {
        window.location.replace("/");
      }
    } catch (err) {
      this.showPopup(err, "error");
      window.location.replace("/");
    }
  },

  computed: {
    accountName() {
      return this.$route.query.name || "";
    },
  },

  methods: {
    ...mapActions("account", ["switchAccount"]),
  },
};
</script>

<style></style>
